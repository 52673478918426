import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { UserManageService } from 'src/app/services/user-manage.service';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { ApiResponse } from 'src/app/models/api-response.model';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-all-users',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, ReactiveFormsModule, RouterModule, NgxUiLoaderModule, NgSelectModule, FormsModule],
  templateUrl: './all-users.component.html',
  styleUrl: './all-users.component.scss'
})
export class AllUsersComponent {
  users: any;
  role: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  isSubmitted: boolean = false;
  statusHandle = [{ name: 'All', value: -1 }, { name: 'Active', value: 1 }, { name: 'Deactive', value: 0 }]
  selectedStatus = this.statusHandle[0].value
  optionalSelect: string = 'All'
  currentPageNumber: any;
  isActivate: any;
  searchFirstName: any = '';
  searchContact: any = '';


  private debounceTimer: any;
  private debounceTimerContact: any;



  private generalUtilityService = inject(GeneralUtilityService);
  private toastr = inject(ToastrService);
  private userService = inject(UserManageService);
  private ngxLoader = inject(NgxUiLoaderService);
  //pageNumber: number | undefined;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "User Management", active: true },
    ];

    this.getAllUsers(this.currentPage, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact);
  }

  hasPermissionForUser(permission: string) {
    return hasPermission(permission);
  }

  onActivateChange(event: any) {
    this.selectedStatus = event?.value;
    this.getAllUsers(this.currentPage, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact)
  }

  searchOnChangeFirstName(value: any) {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      this.searchFirstName = value;
      this.getAllUsers(this.currentPage, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact);
    }, 1000)
  }

  searchOnChangeContactNumber(value: any) {
    clearTimeout(this.debounceTimerContact)
    this.debounceTimerContact = setTimeout(() => {
      this.searchContact = value;
      this.getAllUsers(this.currentPage, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact);
    }, 1000)
  }





  getAllUsers(pageNumber?: number, pageSize?: number, selectedStatus?: number, searchFirstName?: string, searchContact?: string) {
    //console.log('all user ', pageNumber);

    this.ngxLoader.start();
    this.userService.getAllUsersURL(pageNumber, pageSize, selectedStatus, searchFirstName, searchContact).subscribe((response) => {
      this.users = response.data.records;
      this.totalCount = response.data.totalRecords;
    });
    this.ngxLoader.stop();
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    // this.currentPageNumber = pageNumber
    //console.log('laod Page', pageNumber);

    this.getAllUsers(pageNumber, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact);
  }

  deleteUser(id: any) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this user ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.userService.deleteUser(id).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success!",
              text: "User deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.getAllUsers(this.currentPage, this.pageSize, this.selectedStatus, this.searchFirstName, this.searchContact);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }
}
