import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RoleRoutes } from "../routes/role-routes";
import { ApiResponse } from "../models/api-response.model";
import { BlogRoutes } from "../routes/blog-routes";

const getAllBlogCategoriesURL = BlogRoutes.getAllBlogCategoriesURL;
const categoryDetailsURL = BlogRoutes.categoryDetailsURL;
const categoryDeleteURL = BlogRoutes.categoryDeleteURL;
const createCategoryURL = BlogRoutes.createCategoryURL;
const categoryUpdateURL = BlogRoutes.categoryUpdateURL;

const getAllBlogPostsURL = BlogRoutes.getAllBlogPostsURL;
const createBlogPostURL = BlogRoutes.createBlogPostURL;
const blogPostDetailsURL = BlogRoutes.blogPostDetailsURL;
const blogPostUpdateURL = BlogRoutes.blogPostUpdateURL;
const blogPostDeleteURL = BlogRoutes.blogPostDeleteURL;

@Injectable({
  providedIn: "root",
})
export class BlogService {

  private http = inject(HttpClient);

  getAllBlogCategories(pageNumber?:number,pageSize?:number) : Observable<any> {
    let endpoint = `${getAllBlogCategoriesURL}`; 
    if(pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  getCategoryDetails(category_id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${categoryDetailsURL}/${category_id}`);
  }

  createCategory(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createCategoryURL, data);
  }

  updateCategory(id:any,data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(categoryUpdateURL +'/'+ id, data);
  }

  deleteCategory(id:any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(categoryDeleteURL +'/'+ id);
  }

  //blog post

  getAllBlogPosts(pageNumber?:number) : Observable<any> {
    let endpoint = `${getAllBlogPostsURL}`; 
    if(pageNumber) {
      endpoint += `?pages=${pageNumber}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createBlogPost(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createBlogPostURL, data);
  }

  getBlogPostDetails(postId: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${blogPostDetailsURL}/${postId}`);
  }

  updateBlogPost(id:any,data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(blogPostUpdateURL+'/'+ id, data);
  }

  deleteBlogPost(id:any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(blogPostDeleteURL +'/'+ id);
  }

}
