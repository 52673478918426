import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DropzoneComponent, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { BlogService } from 'src/app/services/blog.service';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response.model';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-add-blog',
  standalone: true,
  imports: [CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CKEditorModule,
    DropzoneModule,
    NgxUiLoaderModule],
  templateUrl: './add-blog.component.html',
  styleUrl: './add-blog.component.scss'
})
export class AddBlogComponent {

  breadCrumbItems!: Array<{}>;
  blogs: any;
  blogCategories: any;
  volumes: any;
  parentCategory: any;
  isSubmitted: boolean = false;
  hideButton: number = 0;
  validateMessage: boolean = false;
  selectedFiles: File[] = [];
  images: any;
  public Editor = ClassicEditor;
  config: DropzoneConfigInterface = {
    url: "#",
    maxFilesize: 50,
    acceptedFiles: "image/*",
    autoProcessQueue: false,
    addRemoveLinks: true,
  };
  blogAddForm!: FormGroup;
  public editorConfig: any;
  public model = {
    editorData: "",
  };



  @ViewChild(DropzoneComponent, { static: false })
  myDropzone!: DropzoneComponent;

  private blogService = inject(BlogService);
  private utilService = inject(GeneralUtilityService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Blogs", link: "/blog" },
      { label: "New Post", active: true },
    ];

    this.editorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
          "|",
          "link",
        ],
      },
    };
    this.getAllBlogCategories();
    // Check if CKEditor is loaded
    if (ClassicEditor) {
      // Remove the image upload feature dynamically
      ClassicEditor.defaultConfig = {
        ...ClassicEditor.defaultConfig,
        image: { upload: { enabled: false } },
      };
    }
    this.ngxLoader.start();

    this.blogAddForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      content: new FormControl("", [Validators.required]),
    });
    this.ngxLoader.stop();

  }

  hasPermissionForBlogs(permission: string) {
    return hasPermission(permission);
  }

  getAllBlogCategories(pageNumber?: number) {
    this.ngxLoader.start();
    this.blogService.getAllBlogCategories(pageNumber).subscribe((response) => {
      this.blogCategories = response.data.records;
    });
    this.ngxLoader.stop();
  }

  getDropzoneFiles(): File[] {
    if (this.myDropzone && this.myDropzone.directiveRef) {
      const dropzoneInstance = this.myDropzone.directiveRef.dropzone();
      return dropzoneInstance.files;
    }
    return [];
  }

  async addNewPost() {

    this.validateMessage = false;
    this.isSubmitted = true;
    this.selectedFiles = this.getDropzoneFiles();
    //this.ngxLoader.start();
    if (this.blogAddForm.valid) {
      this.ngxLoader.start();
      if (this.selectedFiles.length > 0) {


        const formData = new FormData();

        for (const element of this.selectedFiles) {
          formData.append('files', element);
        }

        const fileUploadRes$ = this.utilService.fileUpload(formData);
        const fileUploadRes = lastValueFrom(fileUploadRes$);

        fileUploadRes.then((response: ApiResponse) => {

          if (response.data) {
            this.images = response.data.map((item: any) => ({ id: item.id }));
            console.log(response.data);
            console.log(this.blogAddForm.value.items);

            const postAddPayload = {
              title: this.blogAddForm.value.title,
              categoryId: this.blogAddForm.value.category.id,
              content: this.model.editorData,
              files: this.images
            };

            const productCreateRes$ = this.blogService.createBlogPost(postAddPayload);
            const productCreateRes = lastValueFrom(productCreateRes$);

            productCreateRes.then((response: ApiResponse) => {
              this.isSubmitted = false;
              this.toastr.success("Post created successfully", "Success!");
              this.ngxLoader.stop();
              this.router.navigate(['/blog']);


            }, (error) => {
              this.isSubmitted = false;
              console.log(error);
              this.ngxLoader.stop();
            });
          }

        });
      }

    }
    //this.ngxLoader.stop();
  }
}
