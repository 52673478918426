import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DropzoneComponent, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BlogService } from 'src/app/services/blog.service';
import { ToastrService } from 'ngx-toastr';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response.model';
import { lastValueFrom } from 'rxjs';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-edit-blog',
  standalone: true,
  imports: [CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CKEditorModule,
    DropzoneModule,
    NgxUiLoaderModule
  ],
  templateUrl: './edit-blog.component.html',
  styleUrl: './edit-blog.component.scss'
})
export class EditBlogComponent {

  breadCrumbItems!: Array<{}>;
  blogs: any;
  blog: any;
  blogCategories: any;
  volumes: any;
  parentCategory: any;
  isSubmitted: boolean = false;
  hideButton: number = 0;
  validateMessage: boolean = false;
  selectedFiles: File[] = [];
  images: Array<{}> = [];
  postId: any;
  public Editor = ClassicEditor;
  config: DropzoneConfigInterface = {
    url: "#",
    maxFilesize: 50,
    acceptedFiles: "image/*",
    autoProcessQueue: false,
    addRemoveLinks: true,
  };
  blogUpdateForm!: FormGroup;
  public editorConfig: any;
  public model = {
    editorData: "",
  };



  @ViewChild(DropzoneComponent, { static: false })
  myDropzone!: DropzoneComponent;

  private blogService = inject(BlogService);
  private utilService = inject(GeneralUtilityService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Blogs", link: "/blog" },
      { label: "Edit Post", active: true },
    ];
    this.postId = this.route.snapshot.paramMap.get('id');
    this.editorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
          "|",
          "link",
        ],
      },
    };
    this.getAllBlogCategories();
    this.getPostDetails(this.postId);
    // Check if CKEditor is loaded
    if (ClassicEditor) {
      // Remove the image upload feature dynamically
      ClassicEditor.defaultConfig = {
        ...ClassicEditor.defaultConfig,
        image: { upload: { enabled: false } },
      };
    }
    this.ngxLoader.start();

    this.blogUpdateForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      content: new FormControl("", [Validators.required]),
    });
    this.ngxLoader.stop();

  }

  hasPermissionForBlogs(permission: string) {
    return hasPermission(permission);
  }

  getPostDetails(postId: any) {
    this.ngxLoader.start();
    this.blogService.getBlogPostDetails(postId).subscribe((response: ApiResponse) => {
      this.blog = response.data;
      // console.log(this.product.product.productAdd);

      response.data.files.forEach((file: any) => {
        this.images.push({ id: file.file.id });


        this.addImageToDropzone(file.file.id, file.file.path);
      });
      console.log();

      // this.productUpdateForm.value.items = this.product.product.productAdd;

      this.blogUpdateForm.patchValue({
        title: this.blog.title,
        category: this.blog.category.name,
        content: this.blog.content,
      });
      //this.ngxLoader.stop();
    });
    this.ngxLoader.stop();
  }

  addImageToDropzone(fileId: string, imageUrl: string) {



    console.log("inside dropzone", fileId, imageUrl);

    const mockFile = { id: fileId, dataURL: imageUrl, size: 12345 }; // You can adjust size accordingly

    const dropzoneRef = this.myDropzone?.directiveRef?.dropzone();
    if (dropzoneRef) {
      dropzoneRef.emit('addedfile', mockFile);
      dropzoneRef.emit('thumbnail', mockFile, imageUrl);
      dropzoneRef.emit('complete', mockFile);
      // dropzoneRef.files.push(mockFile);
    }
    // console.log(this.images);
    // console.log("dropzoneRef:", dropzoneRef);
  }

  getAllBlogCategories(pageNumber?: number) {
    this.ngxLoader.start();
    this.blogService.getAllBlogCategories(pageNumber).subscribe((response) => {
      this.blogCategories = response.data.records;
    });
    this.ngxLoader.stop();
  }

  getDropzoneFiles(): File[] {
    if (this.myDropzone && this.myDropzone.directiveRef) {
      const dropzoneInstance = this.myDropzone.directiveRef.dropzone();
      return dropzoneInstance.files;
    }
    return [];
  }

  async updatePost() {

    this.validateMessage = false;
    this.isSubmitted = true;
    this.selectedFiles = this.getDropzoneFiles();
    // this.ngxLoader.start();

    if (this.images.length > 0 || this.selectedFiles.length > 0) {
      if (this.blogUpdateForm.valid) {
        this.ngxLoader.start();

        const formData = new FormData();

        for (const element of this.selectedFiles) {
          formData.append('files', element);
        }

        const fileUploadRes$ = this.utilService.fileUpload(formData);
        const fileUploadRes = lastValueFrom(fileUploadRes$);

        fileUploadRes.then((response: ApiResponse) => {

          if (response.data) {
            // this.images = response.data.map((item:any) => ({ id: item.id }));
            response.data.forEach((item: any) => {
              // this.images.push(response.data.map((item:any) => ({ id: item.id })));
              this.images.push({ id: item.id });
            });

            // console.log(this.images);

            const blogUpdatePayload = {
              title: this.blogUpdateForm.value.title,
              categoryId: this.blogUpdateForm.value.category.id,
              content: this.model.editorData,
              files: this.images
            };

            const productCreateRes$ = this.blogService.updateBlogPost(this.blog.id, blogUpdatePayload);
            const productCreateRes = lastValueFrom(productCreateRes$);

            productCreateRes.then((response: ApiResponse) => {
              this.isSubmitted = false;
              this.toastr.success("Blog updated successfully", "Success!");
              this.ngxLoader.stop();
              this.router.navigate(['/blog']);

            }, (error) => {
              this.isSubmitted = false;
              console.log(error);
              this.ngxLoader.stop();
            });
          }

        });
      }
    }
    this.ngxLoader.stop();
  }
  // async updatePost() {

  //   // this.validateMessage = false;
  //   // this.isSubmitted = true;
  //   // this.selectedFiles = this.getDropzoneFiles();
  //   // this.ngxLoader.start();
  //   // if (this.productAddForm.valid) {
  //   //   if(this.selectedFiles.length > 0) {


  //   //     const formData = new FormData();

  //   //     for (const element of this.selectedFiles) {
  //   //       formData.append('files', element);
  //   //     }

  //   //     const fileUploadRes$ = this.utilService.fileUpload(formData);
  //   //     const fileUploadRes = lastValueFrom(fileUploadRes$);

  //   //     fileUploadRes.then((response: ApiResponse) => {

  //   //       if(response.data) {
  //   //         this.images = response.data.map((item:any) => ({ id: item.id }));
  //   //         console.log(response.data);
  //   //         console.log(this.productAddForm.value.items);

  //   //         const postAddPayload = {
  //   //           title: this.productAddForm.value.title,
  //   //           categoryId: this.productAddForm.value.category.id,
  //   //           content: this.model.editorData,
  //   //         };

  //   //         const productCreateRes$ = this.blogService.createBlogPost(postAddPayload);
  //   //         const productCreateRes = lastValueFrom(productCreateRes$);

  //   //         productCreateRes.then((response: ApiResponse) => {
  //   //           this.isSubmitted = false;
  //   //           this.toastr.success("Post Created Successfully", "Success!");
  //   //           this.router.navigate(['/blog']);
  //   //         }, (error) => {
  //   //           this.isSubmitted = false;
  //   //           console.log(error);
  //   //         });
  //   //       }

  //   //     });
  //   // }

  //   // }
  //   // this.ngxLoader.stop();
  // }

  onFileRemoved(file: any) {
    // Handle file removal
    console.log("this is id", file.id);

    this.images = this.images.filter((image: any) => image.id !== file.id);
    console.log('remove image', this.images);

  }
}
