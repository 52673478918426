import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import Swal from 'sweetalert2';
import { ApiResponse } from 'src/app/models/api-response.model';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';
import { NgSelectModule } from '@ng-select/ng-select';
import { values } from 'lodash';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-all-products',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, RouterModule, NgxUiLoaderModule, NgSelectModule, FormsModule],
  templateUrl: './all-products.component.html',
  styleUrl: './all-products.component.scss'
})
export class AllProductsComponent {

  products: any;
  product: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  activeStatus = [{ name: 'All', value: -1 }, { name: 'Active', value: 1 }, { name: 'Deactive', value: 0 }];
  selectedStatus = this.activeStatus[0].value;
  anotherSelect = 'All'
  searchValue: any | string = '';
  searchValueCategory: any | string = ''


  private debounceTimer: any;
  private debounceTimerCategory: any;

  private generalUtilityService = inject(GeneralUtilityService);
  private toastr = inject(ToastrService);
  private productService = inject(ProductService);
  private ngxLoader = inject(NgxUiLoaderService);


  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Products", active: true },
    ];

    this.getAllProducts(this.currentPage, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);

    //this.getAllProducts(this.selectedStatus);
  }

  changeStatus(event: any) {
    this.selectedStatus = event?.value
    this.getAllProducts(this.currentPage, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);
  }


  searchOnChange(value: string) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.searchValue = value;
      this.getAllProducts(this.currentPage, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);
    }, 1000);
  }

  searchOnChangeCategory(value: string) {
    clearInterval(this.debounceTimerCategory);
    this.debounceTimerCategory = setTimeout(() => {
      this.searchValueCategory = value;
      this.getAllProducts(this.currentPage, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);
    }, 1000)
  }


  hasPermissionForProduct(permission: string) {
    return hasPermission(permission);
  }


  getAllProducts(pageNumber?: number, pageSize?: number, selectedStatus?: number, searchValue?: string, searchValueCategory?: string) {
    this.ngxLoader.start();
    this.productService.getAllProducts(pageNumber, pageSize, selectedStatus, searchValue, searchValueCategory).subscribe((response) => {
      this.products = response.data.records;
      this.totalCount = response.data.totalRecords;
      this.ngxLoader.stop();
    });

  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    console.log('page Number', pageNumber);
    // this.currentPage = pageNumber;
    this.getAllProducts(pageNumber, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);
  }

  deleteProduct(productId: number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this product ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.productService.deleteProduct(productId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success!",
              text: "Product deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.getAllProducts(this.currentPage, this.pageSize, this.selectedStatus, this.searchValue, this.searchValueCategory);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }
}
