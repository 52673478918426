<app-breadcrumbs
  title="Product Volumes"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForVolume('VIEW_PRODUCT')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForVolume('VIEW_PRODUCT')">
  <div class="col-12">
    <div class="card">
      <div class="card-header custom-tr">
        <button
          *ngIf="hasPermissionForVolume('CREATE_PRODUCT')"
          type="button"
          class="btn btn-primary waves-effect waves-light"
          data-bs-toggle="modal"
          data-bs-target="#rolecontentModal"
          data-bs-whatever="Mary"
          (click)="openRoleModal(roleDataModal)"
        >
          Add New Volume &nbsp;<strong>+</strong>
        </button>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">Name</th>
                <th class="sort">Status</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of volumes; track item; let i = $index) {
              <tr>
                <td>{{ item.name }}</td>
                <td>
                  @if(item.status == 1) {
                  <span class="badge bg-success-subtle text-success"
                    >Active</span
                  >
                  } @else {
                  <span class="badge bg-warning-subtle text-warning"
                    >Deactive</span
                  >
                  }
                </td>
                <td>
                  <button
                    *ngIf="hasPermissionForVolume('UPDATE_PRODUCT')"
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    data-bs-toggle="modal"
                    data-bs-target="#roleUpdateContentModal"
                    data-bs-whatever="Mary"
                    (click)="openEditModal(categoryUpdateModal, item.id)"
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="hasPermissionForVolume('DELETE_PRODUCT')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteVolume(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #roleDataModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="rolecontentModal">Add Volume</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onModalClose()"
    ></button>
  </div>
  <form (ngSubmit)="createNewVolume()" [formGroup]="volumeAddForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="Name" class="col-form-label"
          >Value (ml) <span class="required-star text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          placeholder="Value (ml)"
          formControlName="Name"
          id="Name"
          restrictedExtraSymbol
          appVolumeInput
        />
        <div
          *ngIf="isSubmitted && volumeAddForm.get('Name')?.hasError('required')"
        >
          <label class="text-danger">Value is required</label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>
      <button type="submit" class="btn btn-primary">Add</button>
    </div>
  </form>
</ng-template>

<ng-template #categoryUpdateModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="roleUpdateContentModal">Edit Volume</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form (ngSubmit)="updateVolume()" [formGroup]="volumeUpdateForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="Name" class="col-form-label">Value (ml):</label>
        <input
          type="number"
          class="form-control"
          placeholder="Value(ml)"
          formControlName="Name"
          id="Name"
          appVolumeInput
          restrictedExtraSymbol
        />
        <div
          *ngIf="
            isSubmitted && volumeUpdateForm.get('Name')?.hasError('required')
          "
        >
          <label class="text-danger">Value is required</label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>
      <button type="submit" class="btn btn-primary">Update</button>
    </div>
  </form>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
