import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from 'src/app/services/order.service';
import { ApiResponse } from 'src/app/models/api-response.model';
import { ToastrService } from 'ngx-toastr';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-view-order',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, RouterModule, NgxUiLoaderModule, NgbAccordionModule],
  templateUrl: './view-order.component.html',
  styleUrl: './view-order.component.scss'
})
export class ViewOrderComponent {

  breadCrumbItems!: Array<{}>;
  orders: any;
  order: any;
  orderId: any;

  private ngxLoader = inject(NgxUiLoaderService);
  private orderService = inject(OrderService);
  private route = inject(ActivatedRoute);
  private toastr = inject(ToastrService);


  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Order Details", active: true },
    ];
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.getOrderDetails(this.orderId);
  }

  hasPermissionForOrders(permission: string) {
    return hasPermission(permission);
  }

  getOrderDetails(orderId: any) {
    this.ngxLoader.start();
    this.orderService.getOrderDetails(orderId).subscribe((response: ApiResponse) => {
      this.order = response.data;
      // console.log(this.product.product.productAdd);
    });
    this.ngxLoader.stop();
  }

  changeStatus(status: string) {
    this.ngxLoader.start();

    const data = {
      orderStatus: status,
    };

    this.orderService.changeOrderStatus(this.orderId, data).subscribe({
      next: (res) => {
        this.toastr.success("Order status updated successfully", "Success!");
        this.order = res.data;
      },
      error: (e) => {
        this.ngxLoader.stop();
      },
    });
    this.ngxLoader.stop();

  }
}