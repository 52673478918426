<app-breadcrumbs
  title="Blogs"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForBlogs('VIEW_BLOG_POST')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForBlogs('VIEW_BLOG_POST')">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header custom-tr"
        *ngIf="hasPermissionForBlogs('CREATE_BLOG_POST')"
      >
        <a
          [routerLink]="['add']"
          class="btn btn-primary waves-effect waves-light"
        >
          New Post
        </a>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">Name</th>
                <th class="sort">Status</th>
                <th class="sort">Posted Date</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of blogs; track item; let i = $index) {
              <tr>
                <td>{{ item.title }}</td>
                <td>
                  @if(item.status == 'active') {
                  <span class="badge bg-success-subtle text-success"
                    >Active</span
                  >
                  } @else {
                  <span class="badge bg-warning-subtle text-warning"
                    >Deactive</span
                  >
                  }
                </td>
                <td>{{ item.createdAt | date : "yyyy-MM-dd" }}</td>
                <td>
                  <button
                    *ngIf="hasPermissionForBlogs('UPDATE_BLOG_POST')"
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    [routerLink]="['/blog/edit', item.id]"
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="hasPermissionForBlogs('DELETE_BLOG_POST')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteBlogPost(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ngx-ui-loader></ngx-ui-loader>
