<app-breadcrumbs
  title="User Management"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForUser('VIEW_USER')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForUser('VIEW_USER')">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header custom-tr"
        *ngIf="hasPermissionForUser('CREATE_USER')"
      >
        <div class="row">
          <span class="col-lg-3">
            <label for="basiInput" class="form-label"
              >Search by user name
            </label>
            <input
              type="text"
              placeholder="Product Name"
              class="form-control"
              id="basiInput"
              [(ngModel)]="searchFirstName"
              (ngModelChange)="searchOnChangeFirstName($event)"
            />
          </span>
          <span class="col-lg-3">
            <label for="basiInput" class="form-label"
              >Search by contact number
            </label>
            <input
              type="text"
              placeholder="Product Name"
              class="form-control"
              id="basiInput"
              [(ngModel)]="searchContact"
              (ngModelChange)="searchOnChangeContactNumber($event)"
            />
          </span>
          <span class="col-lg-3">
            <label for="filter" class="form-label">User status </label>
            <ng-select
              [items]="statusHandle"
              [clearable]="false"
              placeholder="Select status"
              id="filter"
              bindLabel="name"
              [(ngModel)]="optionalSelect"
              class="my-dropdown"
              (change)="onActivateChange($event)"
            >
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </span>

          <div class="col-lg-3 btn_area">
            <a
              type="button"
              class="btn btn-primary waves-effect waves-light"
              [routerLink]="['/user-management/add']"
            >
              Add New User &nbsp; <strong>+</strong>
            </a>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table style="min-width: 90rem" class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">First Name</th>
                <th class="sort">Last Name</th>
                <th class="sort">Email</th>
                <th class="sort">Contact No</th>
                <th class="sort">Status</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of users; track item; let i = $index) {
              <tr>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.contactNo }}</td>
                <td>
                  @if(item.status == 1) {
                  <span class="badge bg-success-subtle text-success"
                    >Active</span
                  >
                  } @else {
                  <span class="badge bg-warning-subtle text-warning"
                    >Deactive</span
                  >
                  }
                </td>
                <td>
                  <button
                    *ngIf="hasPermissionForUser('UPDATE_USER')"
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    [routerLink]="['/user-management/edit', item.id]"
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="hasPermissionForUser('DELETE_USER')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteUser(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ngx-ui-loader></ngx-ui-loader>
