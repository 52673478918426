import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../models/api-response.model";
import { OrderRoutes } from "../routes/order-routes";

const getAllOrdersURL = OrderRoutes.getAllOrdersURL;
const orderDetailsURL = OrderRoutes.orderDetailsURL;
const orderUpdateURL = OrderRoutes.orderUpdateURL;
const changeStatusURL = OrderRoutes.changeStatusURL;

@Injectable({
  providedIn: "root",
})
export class OrderService {

  private http = inject(HttpClient);

  getAllOrders(pageNumber?:number,pageSize?:number) : Observable<any> {
    let endpoint = `${getAllOrdersURL}`; 
    if(pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  getOrderDetails(orderId: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${orderDetailsURL}/${orderId}`);
  }

  changeOrderStatus(id:any,data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(changeStatusURL +'/'+ id, data);
  }

  updateOrder(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(orderUpdateURL, data);
  }

 

}
