import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { BlogService } from 'src/app/services/blog.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from 'src/app/models/api-response.model';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-all-blogs',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, RouterModule, NgxUiLoaderModule],
  templateUrl: './all-blogs.component.html',
  styleUrl: './all-blogs.component.scss'
})
export class AllBlogsComponent {

  blogs: any;
  blog:any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;

  private ngxLoader =  inject(NgxUiLoaderService);
  private blogService = inject(BlogService);
  private toastr = inject(ToastrService);
  private generalUtilityService = inject(GeneralUtilityService);
  

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Blogs", active: true },
    ];

    this.getAllBlogPosts();
  }

  hasPermissionForBlogs(permission: string) {
    return hasPermission(permission);
  }

  getAllBlogPosts(pageNumber?: number) {
    this.ngxLoader.start();
    this.blogService.getAllBlogPosts(pageNumber).subscribe((response) => {
      this.blogs = response.data.records;
    });
    this.ngxLoader.stop();
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    this.getAllBlogPosts(pageNumber);
  }

  deleteBlogPost(productId:number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this blog ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.blogService.deleteBlogPost(productId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success!",
              text: "Blog deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.getAllBlogPosts();
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }
}
