<app-breadcrumbs
  title="New Post"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div
  class="row"
  *ngIf="!hasPermissionForBlogs('CREATE_BLOG_POST')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForBlogs('CREATE_BLOG_POST')">
  <div class="col-lg-12">
    <div class="card">
      <form
        [formGroup]="blogAddForm"
        (ngSubmit)="addNewPost()"
        enctype="multipart/form-data"
      >
        <div class="card-body">
          <div class="live-preview">
            <div class="row gy-4">
              <div class="col-xxl-12 col-md-12">
                <div>
                  <label for="basiInput" class="form-label"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="title"
                    placeholder="Title"
                    id="basiInput"
                    appIgnoreFirstCharacterMinus
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      blogAddForm.get('title')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Title is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12 col-md-12">
                <label for="choices-single-default" class="form-label"
                  >Category <span class="text-danger">*</span></label
                >
                <ng-select
                  [items]="blogCategories"
                  formControlName="category"
                  placeholder="Category"
                  id="category"
                  bindLabel="name"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  *ngIf="
                    isSubmitted &&
                    blogAddForm.get('category')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Category is required
                </p>
              </div>
              <!--end col-->
              <div class="col-xxl-12 col-md-12">
                <label class="form-label"
                  >Images <span class="text-danger">*</span>&nbsp;&nbsp;&nbsp;
                  <span class="image_formate">'png', 'jpg', 'jpeg'</span></label
                >
                <div>
                  <dropzone
                    [config]="config"
                    class="dropzone_sec"
                    #myDropzone
                  ></dropzone>
                  <div *ngIf="isSubmitted && this.selectedFiles">
                    <label class="text-danger">Please Upload Images</label>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-12 col-md-12">
                <label class="form-label"
                  >Content <span class="text-danger">*</span></label
                >
                <ckeditor
                  [editor]="Editor"
                  [config]="editorConfig"
                  formControlName="content"
                  [(ngModel)]="model.editorData"
                ></ckeditor>
                <p
                  *ngIf="
                    isSubmitted &&
                    blogAddForm.get('content')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Content is required
                </p>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <div class="card-footer custom-tr">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
          >
            Create Post
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
<ngx-ui-loader></ngx-ui-loader>
