<header id="page-topbar" data-scroll-header>
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon"
          (click)="toggleMobileMenu($event)"
        >
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div
          class="dropdown d-md-none topbar-head-dropdown header-item"
          ngbDropdown
        >
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i class="bx bx-search fs-22"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
            ngbDropdownMenu
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        @if(flagvalue === undefined){
                        <img src="{{valueset}}" alt="Header Language" height="20" class="rounded">}
                        @else {
                        <img src="{{flagvalue}}" alt="Header Language" height="20" class="rounded">
                        }
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

                       
                        @for(item of listLang;track $index){
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng" (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
                            <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18"> <span class="align-middle">{{item.text}}</span>
                        </a>
                        }
                    </div>
                </div> -->

        <!-- <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-category-alt fs-22'></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-15"> Web Apps </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);" class="btn btn-sm btn-soft-info"> View All Apps
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/github.png" alt="Github">
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dribbble.png" alt="dribbble">
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dropbox.png" alt="dropbox">
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/slack.png" alt="slack">
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

        <!-- <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-cart-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-shopping-bag fs-22'></i>
                        <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-info">{{cart_length}}<span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0" aria-labelledby="page-header-cart-dropdown" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fs-16 fw-semibold"> My Cart</h6>
                                </div>
                                <div class="col-auto">
                                    <span class="badge bg-info-subtle text-info fs-13"> {{cart_length}} items</span>
                                </div>
                            </div>
                        </div>
                        <ngx-simplebar style="max-height: 300px;">
                            <div class="p-2">
                                <div class="text-center empty-cart" id="empty-cart" style="display: none;">
                                    <div class="avatar-md mx-auto my-3">
                                        <div class="avatar-title bg-info-subtle text-info fs-36 rounded-circle">
                                            <i class='bx bx-cart'></i>
                                        </div>
                                    </div>
                                    <h5 class="mb-3">Your Cart is Empty!</h5>
                                    <a routerLink="/ecommerce/products" class="btn btn-success w-md mb-3">Shop Now</a>
                                </div>
                                @for(data of cartData;track $index){
                                <div class="d-block dropdown-item text-wrap px-3 py-2" id="item_{{data.id}}">
                                    <div class="d-flex align-items-center">
                                        <img src="{{data.img}}" class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-grow-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a routerLink="/ecommerce/product-detail/1" class="text-reset">{{data.product}}</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>{{data.quantity}} x ${{data.price}}</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$ <span class="item_price">{{data.quantity *
                                                    data.price}}</span></h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary" (click)="deleteItem($event,data.id)">
                                                <i class="ri-close-fill fs-16"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </ngx-simplebar>
                        <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border d-grid">
                            <div class="d-flex justify-content-between align-items-center pb-3">
                                <h5 class="m-0 text-muted">Total:</h5>
                                <div class="px-2">
                                    <h5 class="m-0">$<span class="total_price">{{total}}</span></h5>
                                </div>
                            </div>

                            <a routerLink="/ecommerce/checkout" class="btn btn-success text-center">
                                Checkout
                            </a>
                        </div>
                    </div>
                </div> -->

        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-toggle="fullscreen"
            (click)="fullscreen()"
          >
            <i class="bx bx-fullscreen fs-22"></i>
          </button>
        </div>

        <!-- <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
                        <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
                    </button>
                </div> -->

        <!-- <div ngbDropdown [autoClose]="false" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" ngbDropdownToggle class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bx bx-bell fs-22'></i>
                        <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

                        <div class="dropdown-head bg-primary  bg-pattern rounded-top">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                    </div>
                                    <div class="col-auto dropdown-tabs">
                                        <span class="badge bg-light-subtle text-body fs-13"> {{allnotifications?.length}} New</span>
                                    </div>
                                </div>
                            </div>

                            <div class="px-2 pt-2">
                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            All ({{allnotifications?.length}})
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div class="pe-2">
                                                        @for(item of allnotifications;track $index){
                                                        <div class="text-reset notification-item d-block dropdown-item position-relative">
                                                            <div class=" d-flex">
                                                                @if(item.img){
                                                                <img src="{{item.img}}" class="me-3 rounded-circle avatar-xs" alt="user-pic">

                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{item.title}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{item.desc}}</p>
                                                                    </div>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i> {{item.time}}</span>
                                                                    </p>
                                                                </div>
                                                                }@else{
                                                                <div class="avatar-xs me-3">
                                                                    <span class="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                                        <i class="bx {{item.icon}}"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-2 lh-base">{{item.desc}}
                                                                        </h6>
                                                                    </a>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i>{{item.time}}</span>
                                                                    </p>
                                                                </div>}


                                                                <div class="px-2 fs-15">
                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="item.state" (change)="onCheckboxChange($event,'1')" value="{{item.id}}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        @if(allnotifications?.length > 0){
                                                        <div class="my-3 text-center">
                                                            <button type="button" class="btn btn-soft-success waves-effect waves-light">View
                                                                All Notifications <i class="ri-arrow-right-line align-middle"></i></button>
                                                        </div>
                                                        }
                                                    </div>

                                                    <div [ngClass]="allnotifications?.length != 0 ?'d-none':''" class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                            <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                        </div>
                                                        <div class="text-center pb-5 mt-2">
                                                            <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                                notifications </h6>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>
                                            Messages
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div class="pe-2">
                                                        @for(message of messages;track $index){
                                                        <div class="text-reset notification-item d-block dropdown-item">
                                                            <div class="d-flex">
                                                                <img src="{{message.avatar}}" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{message.name}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{message.message}}</p>
                                                                    </div>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i> {{message.time_ago}}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="px-2 fs-15">
                                                                    <input class="form-check-input" type="checkbox" value="{{message.id}}" [(ngModel)]="message.state" (change)="onCheckboxChange($event,'2')" id="{{message.checkboxId}}">
                                                                    <label class="form-check-label" for="{{message.checkboxId}}"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        @if(messages?.length > 0){
                                                        <div class="my-3 text-center">
                                                            <button type="button" class="btn btn-soft-success waves-effect waves-light">View
                                                                All Messages <i class="ri-arrow-right-line align-middle"></i></button>
                                                        </div>
                                                        }
                                                    </div>

                                                    <div [ngClass]="messages?.length != 0 ?'d-none':''" class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                            <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                        </div>
                                                        <div class="text-center pb-5 mt-2">
                                                            <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                                notifications </h6>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>
                                            Alerts
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                    <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                </div>
                                                <div class="text-center pb-5 mt-2">
                                                    <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                        notifications </h6>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div class="tab-content" id="notificationItemsTabContent">
                            <div class="tab-content text-muted">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>

                        <div class="notification-actions" id="notification-actions">
                            <div class=" d-flex text-muted justify-content-center">
                                Select <div id="select-content" class="text-body fw-semibold px-1">{{checkedValGet.length}}</div> Result <button type="button" class="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" (click)="openModal(removenotification)" data-bs-target="#removeNotificationModal">Remove</button>
                            </div>
                        </div>
                    </div>
                </div> -->

        <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
          <button
            type="button"
            class="btn"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <span class="d-flex align-items-center">
              <!-- <img
                class="rounded-circle header-profile-user"
                src="assets/images/users/avatar-1.jpg"
                alt="Header Avatar"
              /> -->
              <div
                class="d-flex justify-content-center align-items-center"
                style="
                  width: 40px;
                  height: 40px;
                  background-color: #44a4e0;
                  color: whitesmoke;
                  border-radius: 50%;
                "
              >
                {{ userDisplayName[0] }}
              </div>
              <span class="text-start ms-xl-2">
                <span
                  class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                  >{{ userDisplayName }}</span
                >
                <span
                  class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                  >{{ userDisplayRole }}</span
                >
              </span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <h6 class="dropdown-header">
              {{ "Welcome " + userDisplayName + "!" }}
            </h6>
            <a class="dropdown-item" routerLink="/pages/profile"
              ><i
                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
              ></i>
              <span class="align-middle">Profile</span></a
            >
            <a
              class="dropdown-item"
              href="javascript: void(0);"
              (click)="logout()"
              ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle" data-key="t-logout">Logout</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- removeNotificationModal -->
<ng-template #removenotification let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        id="NotificationModalbtn-close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="mt-2 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#f7b84b,secondary:#f06548"
          style="width: 100px; height: 100px"
        ></lord-icon>
        <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>Are you sure ?</h4>
          <p class="text-muted mx-4 mb-0">
            Are you sure you want to remove this Notification ?
          </p>
        </div>
      </div>
      <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
        <button
          type="button"
          class="btn w-sm btn-light"
          data-bs-dismiss="modal"
          (click)="modal.dismiss('Cross click')"
        >
          Close
        </button>
        <button
          type="button"
          class="btn w-sm btn-danger"
          id="delete-notification"
          (click)="notificationDelete()"
        >
          Yes, Delete It!
        </button>
      </div>
    </div>
  </div>
  <!-- /.modal-content -->
</ng-template>
