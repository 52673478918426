<app-breadcrumbs
  title="Product Categories"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForCategory('VIEW_PRODUCT')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForCategory('VIEW_PRODUCT')">
  <div class="col-12">
    <div class="card">
      <div class="card-header custom-tr">
        <div class="row d-flex justify-content-between align-items-end">
          <div class="col-lg-4 text-start">
            <label for="filter" class="form-label">Category status </label>
            <ng-select
              [clearable]="false"
              placeholder="Select status"
              id="filter"
              bindLabel="name"
              class="my-dropdown"
              [items]="activeStatus"
              [(ngModel)]="anotherSelected"
              (change)="changeTheSelectedStatus($event)"
            >
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="col-lg-4">
            <button
              *ngIf="hasPermissionForCategory('CREATE_PRODUCT')"
              type="button"
              class="btn btn-primary waves-effect waves-light mt-2"
              data-bs-toggle="modal"
              data-bs-target="#rolecontentModal"
              data-bs-whatever="Mary"
              (click)="openRoleModal(roleDataModal)"
            >
              Add New Category &nbsp;<strong>+</strong>
            </button>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">Name</th>
                <!-- <th class="sort">Parent Category</th> -->
                <th class="sort">Status</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of categories; track item; let i = $index) {
              <tr>
                <td>{{ item.name }}</td>
                <!-- <td>Test</td> -->
                <td>
                  @if(item.status == 1) {
                  <span class="badge bg-success-subtle text-success"
                    >Active</span
                  >
                  } @else {
                  <span class="badge bg-warning-subtle text-warning"
                    >Deactive</span
                  >
                  }
                </td>
                <td>
                  <button
                    *ngIf="hasPermissionForCategory('UPDATE_PRODUCT')"
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    data-bs-toggle="modal"
                    data-bs-target="#roleUpdateContentModal"
                    data-bs-whatever="Mary"
                    (click)="openEditModal(categoryUpdateModal, item.id)"
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="hasPermissionForCategory('DELETE_PRODUCT')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteRole(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #roleDataModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="rolecontentModal">Add New Category</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modalClose()"
    ></button>
  </div>
  <form (ngSubmit)="createNewCategory()" [formGroup]="categoryAddForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="roleName" class="col-form-label"
          >Category Name <span class="required-star text-danger">*</span></label
        >
        <input
          placeholder="Category name"
          type="text"
          class="form-control"
          formControlName="categoryName"
          id="categoryName"
          appLimitInput
          appRestrictedTheSymbolValidation
          appIgnoreFirstCharacterMinus
        />
        <div
          *ngIf="
            isSubmitted &&
            categoryAddForm.get('categoryName')?.hasError('required')
          "
        >
          <label class="text-danger">Category name is required</label>
        </div>
      </div>

      <div class="mb-3">
        <label for="roleName" class="col-form-label"
          >Category Image
          <span class="required-star text-danger">*</span></label
        >
        <input
          placeholder="Category Image"
          type="file"
          #fileInput
          (change)="fileChangeEvent($event)"
          class="form-control"
          id="categoryImage"
        />
        <div
          *ngIf="
            isSubmitted &&
            categoryAddForm.get('categoryImage')?.hasError('required')
          "
        >
          <label class="text-danger">Category image is required</label>
        </div>
      </div>
      <div class="col-xxl-12 col-md-12" *ngIf="imageChangedEvent">
        <div class="cropper-container">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [aspectRatio]="cropperAspectRatio"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>

        <button
          type="button"
          *ngIf="imageChangedEvent"
          class="btn btn-primary mt-2 custom-mr-2"
          (click)="addCroppedImage()"
        >
          Add Cropped Image
        </button>

        <button
          type="button"
          *ngIf="imageChangedEvent"
          class="btn btn-danger mt-2 btn-margin"
          (click)="removeCroppedImageEvent()"
        >
          Reset
        </button>
      </div>

      <div class="mb-3">
        <label for="choices-single-default" class="form-label"
          >Order Type</label
        >
        <ng-select
          [items]="orderTypes"
          formControlName="orderType"
          id="parentId"
          bindLabel="name"
          placeholder="Order Type"
          [(ngModel)]="selectedType"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modalClose()">
        Close
      </button>
      <button type="submit" class="btn btn-primary">Add</button>
    </div>
  </form>
</ng-template>

<ng-template #categoryUpdateModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="roleUpdateContentModal">Edit Category</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form (ngSubmit)="updateCategory()" [formGroup]="categoryUpdateForm">
    <div class="modal-body">
      <div class="">
        <label for="categoryName" class="col-form-label"
          >Category Name <span class="required-star text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="categoryName"
          id="categoryName"
          appIgnoreFirstCharacterMinus
          appLimitInput
          appRestrictedTheSymbolValidation
        />
        <div
          *ngIf="
            isSubmitted &&
            categoryUpdateForm.get('categoryName')?.hasError('required')
          "
        >
          <label class="text-danger">Category name is required</label>
        </div>
      </div>

      <div class="mb-3">
        <label for="roleName" class="col-form-label"
          >Category Image
          <span class="required-star text-danger">*</span></label
        >
        <input
          placeholder="Category Image"
          type="file"
          #fileInput
          (change)="fileChangeEvent($event)"
          class="form-control"
          id="categoryImage"
        />
        <div
          *ngIf="
            isSubmitted &&
            categoryAddForm.get('categoryImage')?.hasError('required')
          "
        >
          <label class="text-danger">Category image is required</label>
        </div>
      </div>

      <div
        *ngIf="selectedImage"
        [ngStyle]="{ display: isClickImage === false ? 'none' : 'block' }"
      >
        <img
          [src]="selectedImage"
          alt="Image Preview"
          class="img-thumbnail mt-2"
          style="max-width: 150px"
        />
      </div>

      <div class="col-xxl-12 col-md-12" *ngIf="imageChangedEvent">
        <div class="cropper-container">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [aspectRatio]="cropperAspectRatio"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>

        <button
          type="button"
          *ngIf="imageChangedEvent"
          class="btn btn-primary mt-2 custom-mr-2"
          (click)="addCroppedImage()"
        >
          Add Cropped Image
        </button>

        <button
          type="button"
          *ngIf="imageChangedEvent"
          class="btn btn-danger mt-2 btn-margin"
          (click)="removeCroppedImageEvent()"
        >
          Reset
        </button>
      </div>

      <div class="mb-3 mt-3">
        <label for="choices-single-default" class="form-label"
          >Order Type</label
        >
        <ng-select
          [items]="orderTypes"
          id="parentId"
          bindLabel="name"
          placeholder="Order Type"
          formControlName="orderType"
          [(ngModel)]="selectedType"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>

      <!-- <div class="mb-3" style="display: none">
        <label for="choices-single-default" class="form-label"
          >Parent Category</label
        >
        <ng-select
          [items]="categories"
          formControlName="parentId"
          id="parentId"
          bindLabel="name"
          placeholder="Parent Category"
          [(ngModel)]="parentCategory"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div> -->
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>
      <button type="submit" class="btn btn-primary">Update</button>
    </div>
  </form>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
