<app-breadcrumbs
  title="Add User"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div
  class="row"
  *ngIf="!hasPermissionForUser('CREATE_USER')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForUser('CREATE_USER')">
  <div class="col-lg-12">
    <div class="card">
      <form [formGroup]="userAddForm" (ngSubmit)="createUser()">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">User Details</h4>
        </div>
        <!-- end card header -->
        <div class="card-body">
          <div class="live-preview">
            <div class="row gy-4">
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="firstName" class="form-label">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                    placeholder="First name"
                    id="firstName"
                    appLimitInput
                    inputValidate="text"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      userAddForm.get('firstName')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    First Name is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="lastName" class="form-label">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                    placeholder="Last name"
                    id="lastName"
                    appLimitInput
                    inputValidate="text"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      userAddForm.get('lastName')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Last Name is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="contactNo" class="form-label">Phone Number</label>
                  <input
                    type="phone"
                    class="form-control"
                    formControlName="contactNo"
                    placeholder="Phone Number"
                    id="contactNo"
                    inputValidate="mobile"
                    placeholder="07XXXXXXXX"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      userAddForm.get('contactNo')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Phone number is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    placeholder="Email"
                    id="email"
                    appLimitInput
                  />
                  <p
                    *ngIf="
                      userAddForm.get('email')?.hasError('email') &&
                      userAddForm.get('email')?.touched
                    "
                    class="text-danger m-0"
                  >
                    Please enter a valid email
                  </p>
                  <p
                    *ngIf="
                      isSubmitted &&
                      userAddForm.get('email')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Email is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="password" class="form-label">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    placeholder="Password"
                    id="password"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      userAddForm.get('password')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Password is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <label for="choices-single-default" class="form-label"
                  >Role</label
                >
                <ng-select
                  [items]="roles"
                  formControlName="role"
                  placeholder="Role"
                  id="role"
                  bindLabel="name"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  *ngIf="
                    isSubmitted && userAddForm.get('role')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Role is required
                </p>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <div class="card-footer custom-tr">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
          >
            Create User
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
<ngx-ui-loader></ngx-ui-loader>
