import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { RoleService } from 'src/app/services/role.service';
import { UserManageService } from 'src/app/services/user-manage.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxUiLoaderModule
  ],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss'
})
export class AddUserComponent {

  breadCrumbItems!: Array<{}>;
  roles: any;
  parentCategory: any;
  isSubmitted: boolean = false;
  userAddForm!: FormGroup;

  private toastr = inject(ToastrService);
  private router = inject(Router);
  private roleService = inject(RoleService);
  private userService = inject(UserManageService);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "User Management", link: "/user-management" },
      { label: "Add", active: true },
    ];

    this.getAllRoles();
    this.userAddForm = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      contactNo: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      role: new FormControl(null, [Validators.required]),
    });

  }

  hasPermissionForUser(permission: string) {
    return hasPermission(permission);
  }

  getAllRoles(pageNumber?: number) {
    this.ngxLoader.start();
    this.roleService.getAllRoles(pageNumber).subscribe((response) => {
      this.roles = response.data.records;
    });
    this.ngxLoader.stop();
  }

  createUser() {
    console.log(this.userAddForm.value);

    this.isSubmitted = true;
    this.ngxLoader.start();
    if (this.userAddForm.valid) {
      const data = {
        firstName: this.userAddForm.value.firstName,
        lastName: this.userAddForm.value.lastName,
        contactNo: this.userAddForm.value.contactNo,
        email: this.userAddForm.value.email,
        password: this.userAddForm.value.password,
        role: this.userAddForm.value.role,
      };

      this.userService.createUser(data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.toastr.success("User added successfully", "Success!");
          this.router.navigate(['/user-management']);
        },
        error: (e) => {
          this.isSubmitted = false;
        },
      });
    }
    else {
      console.log("not valid");

    }
    this.ngxLoader.stop();
  }
}
