<app-breadcrumbs
  title="Orders"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForOrders('VIEW_ORDER')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForOrders('VIEW_ORDER')">
  <div class="col-12">
    <div class="card">
      <!-- <div class="card-header custom-tr">
              <a
              [routerLink]="['add']"
              class="btn btn-primary waves-effect waves-light"
            >
              New Post
            </a>
          </div> -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">Order Reference ID</th>
                <th class="sort">Order Date</th>
                <th class="sort">Total</th>
                <th class="sort">Status</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of orders; track item; let i = $index) {
              <tr>
                <td>{{ item.code }}</td>
                <td>{{ item.createdAt | date : "yyyy-MM-dd" }}</td>
                <td>{{ item.subTotal }}</td>
                <td>
                  @if(item.orderStatus == 'Pending') {
                  <span class="badge bg-warning-subtle text-warning">{{
                    item.orderStatus
                  }}</span>
                  } @if(item.orderStatus == 'On Progress') {
                  <span class="badge bg-primary-subtle text-primary">{{
                    item.orderStatus
                  }}</span>
                  } @if(item.orderStatus == 'Delivered') {
                  <span class="badge bg-success-subtle text-success">{{
                    item.orderStatus
                  }}</span>
                  }
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    [routerLink]="['/order/view', item.id]"
                  >
                    View
                  </button>
                  <!-- <button
                            type="button"
                            class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                            (click)="deleteBlogPost(item.id)"
                          >
                            Delete
                          </button> -->
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ngx-ui-loader></ngx-ui-loader>
